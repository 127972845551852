
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.universal-certificate-v2__wrapper {
  margin-right: 4px;
  margin-left: 4px;
  padding-top: 56px;
  padding-bottom: 56px;
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
    border-radius: 32px;
  }

  @include media(xl) {
    width: calc(100vw - 40px);
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
  }
}

.program-v6 + .universal-certificate-v2 {
  margin-top: -104px;
  padding-top: 0;

  @include media(lg) {
    margin-top: -148px;
  }

  .universal-certificate-v2__wrapper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.universal-certificate-v2__inner {
  padding-top: 32px;
  padding-bottom: 24px;
  border-radius: 20px;
  color: var(--accent-always-white-color);
  background-color: var(--bg-accent-color);

  @include media(sm-max) {
    margin-right: 16px;
    margin-left: 16px;
  }

  @include media(md) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 268px 1fr;
    padding-right: 32px;
    padding-bottom: 32px;
  }

  @include media(lg) {
    grid-template-columns: 368px 1fr;
    padding: 56px 48px 56px 0;
    border-radius: 32px;
  }

  @include media(xl) {
    grid-column-gap: 132px;
    grid-template-columns: 468px 1fr;
    padding: 92px 100px 92px 0;
  }
}

.universal-certificate-v2__title {
  margin-bottom: 32px;

  @include media(md) {
    grid-row: 1/2;
    grid-column: 2/-1;
    margin-bottom: 16px;
  }

  @include media(lg) {
    margin-bottom: 24px;
  }
}

.universal-certificate-v2__pic {
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  @include media(sm-max) {
    margin-bottom: 32px;
    padding-top: 100%;
    background-color: var(--accent-always-black-color);
  }

  @include media(md) {
    grid-row: 1/-1;
    grid-column: 1/2;
    align-self: flex-end;
    width: 268px;
    height: 268px;
    margin-top: -32px;
    margin-bottom: -32px;
  }

  @include media(lg) {
    width: 368px;
    height: 368px;
    margin-top: -56px;
    margin-bottom: -56px;
  }

  @include media(xl) {
    width: 468px;
    height: 468px;
    margin-top: -92px;
    margin-bottom: -92px;
  }

  img {
    @include media(sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.universal-certificate-v2__desc {
  margin-bottom: 32px;

  @include media(md) {
    grid-row: 2/3;
    grid-column: 2/-1;
  }

  @include media(lg) {
    margin-bottom: 40px;
  }
}

.universal-certificate-v2__button {
  @include media(md) {
    grid-row: 3/-1;
    grid-column: 2/-1;
    align-self: flex-start;
  }
}
